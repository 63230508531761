/**
 * Created by Marko Miletic on 21.12.2015..
 * Edits by Marko Vukovic marko@fiktiv.co
 */
/**
 * Define application.
 *
 * @returns {{init: Function}}
 */
window.App = function () {
    /**
     * Initialize application.
     */
    var init = function () {

        sliderInitialization();

        cookieNotice();
        //showMore();
        openSearch();

        if($('select').length){
            startSelectize();
        }

        VideoJS.setupAllWhenReady();
    };

    var startSelectize = function(){

        var selectizeSelect = $('select')[0],
            dataDefault = selectizeSelect.getAttribute('data-default'),
            dataSelected = selectizeSelect.getAttribute('data-selected');

        var selected = dataSelected ? dataSelected : dataDefault;

        var url = window.location.href;



        $('select').selectize({
            dropdownParent: 'body',
            placeholder : selected,
            onChange : function(value){
                var url = window.location.href;
                url = url.substring(0, url.indexOf('/archive-cycles'));
                url= url + '/archive-cycles/'+value;
                window.location.replace(url);
            }
        });
    };

    var cookieNotice = function(){
        var cookieContainer = $("#cookie-message");

        if(readCookie('cookie-message') === 'hide'){
            cookieContainer.hide();
        } else {
            var button = $("#hide-cookie-notice");
            button.on('click', function(){
                createCookie('cookie-message', 'hide');
                cookieContainer.hide();
            });
        }
    };

    // Create cookie
    var createCookie = function(name, value, days) {
        var expires;
        if (days) {
            var date = new Date();
            date.setTime(date.getTime()+(days*24*60*60*1000));
            expires = "; expires="+date.toGMTString();
        }
        else {
            expires = "";
        }
        document.cookie = name+"="+value+expires+"; path=/";
    };

    // Read cookie
    var readCookie = function(name){
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1,c.length);
            }
            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length,c.length);
            }
        }
        return null;
    };

    // Erase cookie
    var eraseCookie = function(name) {
        createCookie(name,"",-1);
    };

    /**
     * Initialize slider
     */
    var sliderInitialization = function () {

        var slider = $('.slider');
        var slidesContainer = $('.slides-container');
        var slide = $('.slider .slides-container .col');
        var arrowLeft = $('.arrow.left');
        var arrowRight = $('.arrow.right');

        var slideCount = $('.slider .slides-container .col').length;
        var sliderWidth = $('.slider').outerWidth();
        var slidesContainerWidth;
        var slideWidth;
        var currentSlide;
        var present = slide.filter('.present');
        var ffwd = slide.filter('.ffwd');
        var lastPastItem = $('.five-col .col.fifth.past:last');
        var index = present.index();
        var windowWidth = $(window).outerWidth();
        var columnCount;

        // Function to get the Max value in Array
        slide.max = function (array) {
            return Math.max.apply(Math, array);
        };

        // Function to get the Min value in Array
        slide.min = function (array) {
            return Math.min.apply(Math, array);
        };

        var heights = $('.slider .slides-container .col').map(function () {
            return $(this).outerHeight();
        }).get();

        if (windowWidth > 960) {
            if(slider.hasClass('movies')) {
              slider.height(slide.max(heights) + 20);
            }
            slide.height(slide.max(heights) + 20);
            if ($('.slider').hasClass('four-col')) {
                $('.border-white').height(slide.max(heights)-190);
                slideWidth = 25;
                columnCount = 4;
                currentSlide = 0;
            } else if ($('.slider').hasClass('five-col')) {
                $('.border-white').height(slide.max(heights)+40);
                slideWidth = 20;
                columnCount = 5;
                //currentSlide = index - 1;
                currentSlide = 0;
            }
        } else if (windowWidth <= 960 && windowWidth > 767) {
            slider.height(slide.max(heights) + 20);
            slide.height(slide.max(heights) + 20);
            if ($('.slider').hasClass('four-col')) {
                slideWidth = 33.33333333;
                currentSlide = 0;
            } else if ($('.slider').hasClass('five-col')) {
                slideWidth = 25;
                currentSlide = index - 1;
            }
        }

        if (windowWidth <= 767) {
            return;
        } else {

            // Fast Forward
            if(ffwd.index()>0) {
              currentSlide = ffwd.index();
              slidesContainer.animate({
                  marginLeft: '-=' + slideWidth*currentSlide + '%'
              }, function () {
                  slide.eq(currentSlide).addClass('active').removeClass('prev');
                  $('.past').css('opacity', 1);
              })
            }
            // force ffwd to last projection from the past
            if ($('.slider').hasClass('five-col') && lastPastItem.index()>0) {
                currentSlide = lastPastItem.index()+1;
                if(currentSlide > slideCount-5)
                  currentSlide = slideCount-5
                slidesContainer.animate({
                    marginLeft: '-=' + slideWidth*currentSlide + '%'
                }, function () {
                    slide.eq(currentSlide).addClass('active').removeClass('prev');
                })
            }

            // Add classes to slides
            slide.eq(currentSlide).addClass('active');
            slide.eq(currentSlide).prevAll('.col').addClass('prev');
            slide.eq(currentSlide).nextAll('.col').addClass('next');

            $('.slides-container').css({width: (slideCount * slideWidth) + "%"});
            slidesContainerWidth = $('.slides-container').outerWidth();

            $('.slider .slides-container .col').css({
                width: (sliderWidth / slidesContainerWidth * slideWidth) + '%'
            });

            arrowLeft.click(function () {
                if ($('.col.active').index() == 0) {
                    return;
                } else {
                    slidesContainer.animate({
                        marginLeft: '+=' + slideWidth + '%'
                    }, function () {
                        slide.eq(currentSlide).removeClass('active').addClass('prev');
                        currentSlide--;
                        slide.eq(currentSlide).addClass('active').removeClass('next');
                        //$('.past').css('opacity', 1);
                    })
                }
                // check if last +  hide arrow if it is
                if ($('.col.active').index()-1 == 0) {
                    $(this).css('opacity',0.3);
                }
                else {
                    $('.arrow.right').css('opacity',1);
                }
            })

            arrowRight.click(function () {
                if ($('.col.active').index() == slideCount - columnCount) {
                    return;
                } else {
                    slidesContainer.animate({
                        marginLeft: '-=' + slideWidth + '%'
                    }, function () {
                        slide.eq(currentSlide).removeClass('active').addClass('next');
                        currentSlide++;
                        slide.eq(currentSlide).addClass('active').removeClass('prev');
                        //$('.past').css('opacity', 1);
                    })
                }
                // check if last +  hide arrow if it is
                if ($('.col.active').index()+1 == slideCount - columnCount) {
                    $(this).css('opacity',0.3);
                }
                else {
                    $('.arrow.left').css('opacity',1);
                }
            })

            // hide arrows
            if(slide.length<=columnCount) {
              arrowRight.hide();
              arrowLeft.hide();
            }

            // Swipe on overlay div
            $('.slider-swipe-overlay').swipe({
                swipe: function (event, direction) {
                    // Check and handle swipe direction
                    if (direction === "left") {
                        if ($('.col.active').index() == slideCount - 4) {
                            return;
                        } else {
                            slidesContainer.animate({
                                marginLeft: '-=' + slideWidth + '%'
                            }, function () {
                                slide.eq(currentSlide).removeClass('active').addClass('next');
                                currentSlide++;
                                slide.eq(currentSlide).addClass('active').removeClass('prev');
                                $('.past').css('opacity', 1);
                            })
                        }
                    } else if (direction === "right") {
                        if ($('.col.active').index() == 0) {
                            return;
                        } else {
                            slidesContainer.animate({
                                marginLeft: '+=' + slideWidth + '%'
                            }, function () {
                                slide.eq(currentSlide).removeClass('active').addClass('prev');
                                currentSlide--;
                                slide.eq(currentSlide).addClass('active').removeClass('next');
                                $('.past').css('opacity', 1);
                            })
                        }
                    }
                }
            });
        }
    };

    var hideCookies = function () {
        var cookies = $('.cookies-container');
        var cookiesButton = $('.cookies-container .cookies-text-container > a.button');

        cookiesButton.click(function () {
            cookies.hide();
        })
    };

    var showMore = function () {

        if ($('.show-more').length == 0) {
            return;
        }

        var linkSlider = $('.show-more.for-slider');
        var linkSchedule = $('.show-more.for-schedule');
        var sliderItem = $('.slider .col');
        var scheduleItem = $('.schedule .schedule-item');
        var scheduleItemCount = scheduleItem.length;
        var sliderItemCount = sliderItem.length;
        var counterMin = 4;
        var counterMax = 7;
        if (sliderItemCount <= 4) {
            linkSlider.addClass('hidden');
        }

        if (scheduleItemCount <= 4) {
            linkSchedule.addClass('hidden');
        }
        //$('.grid-item:gt(19)').hide();

        linkSlider.click(function (e) {
            e.preventDefault();
            for (var i = counterMin; i <= counterMax; i++) {
                sliderItem.eq(i).css('display', 'block');
            }

            counterMin += 4;
            counterMax += 4;

        })

        linkSchedule.click(function (e) {
            e.preventDefault();
            for (var i = counterMin; i <= counterMax; i++) {
                scheduleItem.eq(i).css('display', 'block');
            }

            counterMin += 4;
            counterMax += 4;

        })
    };

    var openSearch = function () {
        var searchIcon = $('header .icon-search-icon');
        var searchInput = $('header .search-input');
        searchIcon.click(function () {
            searchInput.addClass('show');
            searchInput.focus();
        })

        searchInput.focusout(function () {
            searchInput.removeClass('show');
        })
    };

    return {init: init};
};
